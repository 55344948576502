import { useState } from "react";

export const useForceUpdate = () => {
  const [, setValue] = useState(0); // Usa solo il secondo elemento dell'array
  return () => setValue((value) => !value);
};

export function isEmpty(variable) {
    if (typeof variable === 'undefined' || variable === null) {
        return true;
    }
    
    if (typeof variable === 'string') {
        return variable.trim().length === 0;
    }
    
    if (Array.isArray(variable)) {
        return variable.length === 0;
    }
    
    if(typeof variable === 'object') {
        return Object.keys(variable).length === 0;
    }
    
    return false;
}

/**
 * console logging function wrapper
 * 
 * @params {string | array } messages
 * @param {number} severity 
 */
export const consoleLog = (messages, severity) => {
  const logEnabled = !process.env.REACT_APP_LOG_DISABLED;
  const logSeverity = process.env.REACT_APP_LOG_SEVERITY ?? 4; // 1 errori, 2 warning, 3 info, 4 debug

  if (severity === undefined) severity = 4;

  if (logEnabled && severity <= logSeverity) {
    let logger;

    switch (severity) {
      case 1:
        logger = console.error;
        break;
      case 2:
        logger = console.warn;
        break;
      case 4:
        logger = console.debug;
        break;
      case 3:
      default:
        logger = console.info;
    }

    if (typeof messages === "object") {
      logger(...messages);
    } else {
      logger(messages);
    }
  }
};

/*** Common settings */

export const toolbar = {
  multiline: true,
  items: [
  "undo", "redo", "separator",
  "bold", "italic", "strike", "underline", "separator",
  {
      "name": "header",
      "acceptedValues": [
          1,
          2,
          3,
          false
      ]
  }, "fontSize", "separator",
  "alignLeft", "alignCenter", "alignRight", "alignJustify", "separator",
  "orderedlist", "bulletlist", "separator",
  "color", "background", "separator",
  "link", "image", "separator", "clear", "codeBlock", "blockquote", "separator",
  "insertTable", "separator",
  // "fullScreen"
]}
