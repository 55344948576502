import React from 'react';
import { evalRule } from './helpers';

const Bold = (props) => {
    const { rules, children: value} = props;

    if(evalRule(...rules["condition"]) === false) {
        if(rules["false-color"] !== undefined) {
            return <strong style={{color: rules["false-color"]}}>{value}</strong>;
        }

        return value;
    } else {
        if(rules["true-color"] !== undefined) {
            return <strong style={{color: rules["true-color"]}}>{value}</strong>;
        }
        
        return <strong>{value}</strong>;
    }
};

export default Bold;