/*
 * File: MainFooter.js
 * Project: kpc_rentastar_2024
 * File Created: Monday, 27th May 2024 3:21:01 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Tuesday, 18th June 2024 3:58:44 pm
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */
// importing CHAKRA
import { Box, HStack } from "@chakra-ui/react"

// importing dependencies
import SwissFlag from "../assets/icons/swiss_flag.png"

export default function MainFooter() {
    return(
        <HStack
            alignItems="center"
            justifyContent="space-between"
            height="100%"
            p={2}
        >
            <HStack>
                <Box>Made with honor in </Box>
                <img src={SwissFlag} alt="Swiss Flag" width="16px" />
            </HStack>
            <Box>
                Welcome back ???
            </Box>
        </HStack>
    )
}