import { EmptyItem, GroupItem, Item, SimpleItem, Tab, TabbedItem, TabPanelOptions } from 'devextreme-react/form';
import { CheckBox } from 'devextreme-react/check-box';
import { DateBox } from 'devextreme-react/date-box';
import { NumberBox } from 'devextreme-react/number-box';
import { SelectBox } from 'devextreme-react/select-box';
import { Switch } from 'devextreme-react/switch';
import { TextBox } from 'devextreme-react/text-box';
import { TextArea } from 'devextreme-react/text-area';
import { FileUploader } from 'devextreme-react/file-uploader';

import React from 'react';
import { consoleLog } from '../ClaxHelpers';
import { claxApi, claxNotify } from '../ClaxApi';

const LoadCustomField = (props) => {
    const { component, formData, handleItemValidation } = props;

    const path = './extensions/' + component + '.js'
    const CustomField = require(`${path}`)

    return (
        <CustomField.default formData={formData} handleItemValidation={handleItemValidation}/>
    )
}

const FormBuilder = (props) => {
    const { url, formData, formOptions, formItems } = props;

    if(formItems === undefined || (Array.isArray(formItems) && formItems.length === 0) || (typeof formItems === 'object' && Object.keys(formItems).length === 0)) {
        return <></>
    }

    const handleItemChange = (e) => {
        console.log(e)
        debugger
    }

    const handleClick = (e) => {
        console.log(e)
        debugger
    }

    const handleItemValidation = async (e) => {
        return claxApi({
            url: url + '/validate',
            method: "POST",
            data: {
                field: e.formItem.dataField,
                value: e.value,
                formData: {...formData, [e.formItem.dataField]: e.value}
            }
        }).then(response => {
            if(response.data.code && response.data.data[e.formItem.dataField].length) {
                e.rule.message = response.data.data[e.formItem.dataField][0]
            } else {
                e.rule.message = "Valid"
            }

            return (response.data.code === 0)
        }).catch(error => {
            claxNotify(error, "error")
        })
    }

    let formBuilt = [];

    formBuilt.push(formItems.map((item, itemIndex) => {
        if(item.length === 0) {
            return <EmptyItem key={itemIndex} />
        }

        const editorType = item.editorType;
        const {options: customOptions, items: items, ...itemOptions} = item
        const deepCopiedFormItems = items?JSON.parse(JSON.stringify(items)):[]; // Necessary to avoid the form items to be modified by the FormBuilder component

        if(editorType === "groupItem") {
            return (            
                <GroupItem key={"groupItem_" + itemIndex} {...itemOptions}>
                    {
                        FormBuilder({url: url, formData: formData, formOptions: customOptions, formItems: deepCopiedFormItems})
                    }
                </GroupItem>
            )
        }

        if(editorType === "tabbedItem") {
            return (
                <TabbedItem key={'tabbedItem_'+ itemIndex}>
                    {
                        items.map((tab, tabIndex) => {
                            const deepCopiedFormItems = tab.items?JSON.parse(JSON.stringify(tab.items)):[];
                            
                            return (
                                <Tab key={'tab_' + tabIndex} title={tab.title}>
                                {
                                    FormBuilder({url: url, formData: formData, formOptions: customOptions, formItems: deepCopiedFormItems})
                                }
                                </Tab>
                            )
                        })
                    }
                </TabbedItem>
            )
        }

        if(item.validationRules === undefined) {
            item.validationRules = []
        }

        if(formOptions?.disableAsyncValidation === true) {
            item.validationRules.push({
                type: 'async',
                validationCallback: handleItemValidation
            })
        }

        if(item.editorOptions?.onClick) {
            itemOptions.editorOptions.onClick = handleClick
        }
         
        if(item.editorOptions?.onValueChanged) {
            itemOptions.editorOptions.onValueChanged = handleItemChange
        }

        if(editorType === "claxCustomBox") {
            try {
                return (
                    <Item key={'item_'+ itemIndex} {...itemOptions}>
                        <LoadCustomField {...customOptions} formData={formData} handleItemValidation={handleItemValidation}/>
                    </Item>
                );
            } catch (error) {
                return <Item key={itemIndex}>UNKNOWN COMPONENT</Item>
            }
        }

        if(item.editorType === "dxFileUploader") {

        }

        return (
            <Item
                key={'item_'+ itemIndex}
                {...itemOptions}
            />
        );
    }));

    consoleLog("Returning form")
    return formBuilt;
}

export default FormBuilder