/*
 * File: index.js
 * Project: kpc_rentastar_2024
 * File Created: Monday, 5th February 2024 9:29:24 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Friday, 24th May 2024 11:37:37 am
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// basic imports
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary'
import { ChakraProvider } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'

// import dependencies
import GenericError from './components/helpers/error/GenericError';

// themes and breakpoints
const breakpoints = {
  base: '0px',
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
  '3xl': '1920',
  '4xl': '2560',
}

// extending app theme
const theme = extendTheme({ breakpoints })

// defining fallback error visual
function ErrorFallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <GenericError error={error} reset={resetErrorBoundary}/>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={(details) => {
      // Reset the state of your app so the error doesn't happen again
      console.log(`Details onReset`)
    }}
  >
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
