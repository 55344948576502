import React from 'react';

const YesNo = (props) => {
    const { rules, children: value} = props;

    if(rules["yes-color"] !== undefined && value) {
        return <div style={{color: rules["yes-color"]}}>{value?'Yes':'No'}</div>;
    }
    
    if(rules["no-color"] !== undefined && !value) {
        return <div style={{color: rules["no-color"]}}>{value?'Yes':'No'}</div>;
    }

    return <div>{value?'Yes':'No'}</div>
}

export default YesNo;