/*
 * File: HomePage.js
 * Project: kpc_rentastar_2024
 * File Created: Sunday, 26th May 2024 4:35:14 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Friday, 12th July 2024 8:04:56 am
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */

// importing CHAKRA
import { GridItem } from "@chakra-ui/react"
import { Box as ChakraBox, VStack as ChakraVStack } from '@chakra-ui/react';
import { Tabs as ChakraTabs, TabList as ChakraTabList, TabPanels as ChakraTabPanels, Tab as ChakraTab, TabPanel as ChakraTabPanel } from '@chakra-ui/react'

// importint LAYOUT
import MainGridLayout from "../../../layouts/MainGridLayout"

// importing dependencies
import MainHeader from "../../../layouts/MainHeader"
import MainFooter from "../../../layouts/MainFooter"

// TEST TEST TEST
import PageTemplate from "../test/PageTemplate";
import EmployeesGrid from "../../../data/EmployeesGrid"
import CarWarehouse from "../drm/CarWarehouse"
import CarModel from "../drm/CarModel"
import Translations from "../admin/Translations";

export default function HomePage() {
    return(
        <MainGridLayout>
            <GridItem area={'header'}>
                < MainHeader />
            </GridItem>
            <GridItem area={'main'}>

                <ChakraVStack align='stretch' >
                    <ChakraTabs
                        isLazy
                    >
                        <ChakraTabList>
                            <ChakraTab>Modelli auto</ChakraTab>
                            <ChakraTab>Vetture correnti</ChakraTab>
                            <ChakraTab>Translations</ChakraTab>
                        </ChakraTabList>

                        <ChakraTabPanels>
                            <ChakraTabPanel p="0">
                                <ChakraVStack gap={2} align='stretch' >
                                    <CarModel />
                                </ChakraVStack>
                            </ChakraTabPanel>
                            <ChakraTabPanel p="0">
                                <ChakraVStack gap={2} align='stretch' >
                                    <CarWarehouse />
                                </ChakraVStack>
                            </ChakraTabPanel>
                            <ChakraTabPanel p="0">
                                <ChakraVStack gap={2} align='stretch' >
                                    <Translations />
                                </ChakraVStack>
                            </ChakraTabPanel>
                        </ChakraTabPanels>
                    </ChakraTabs>
                </ChakraVStack>

            </GridItem>
            <GridItem area={'footer'} borderTop="1px solid" borderColor="gray.200">
                <MainFooter />
            </GridItem>
        </MainGridLayout>
    )
}

