import React, { useEffect, useState } from 'react';

import { Form, GroupItem, Item } from 'devextreme-react/form';

import { claxNotify, useFetch } from '../../../ClaxApi';
import FormBuilder from '../../ClaxFormBuilder';

const ConnectorType = (props) => {
    const [idSourceType, setIdSourceType] = useState(props.formData?.id_data_source_type ?? null);

    const { id_data_source, params } = props.formData??{id_data_source: 0, params: null};

    const { data: connectorData, loading: connectorLoading, error: connectorError } = useFetch({
        url:  process.env.REACT_APP_API_URL + '/l0/data-source-type',
    }, [id_data_source]);

    const { data: connectorTypeData, loading: connectorTypeLoading, error: connectorTypeError } = useFetch({
        url:  process.env.REACT_APP_API_URL + '/l0/data-source-type'+ (idSourceType === null?'/0':'/' + idSourceType) + '/form',
    }, [idSourceType])

    const handleSetConnectorType = (e) => {
        setIdSourceType(e.selectedItem?.id_data_source_type??null)
    }

    if(connectorLoading || connectorTypeLoading) {
        return <></>
    }

    if(connectorError) {
        claxNotify(connectorError, "error")
    }

    if(connectorTypeError) {
        claxNotify(connectorTypeError, "error")
    }

    return (
        <>
            <Form formData={props.formData}>
                <GroupItem key="connectorTypes" colCount={3}>
                    <Item
                        editorType='dxSelectBox'
                        dataField='id_data_source_type'
                        editorOptions={{
                            dataSource: connectorData,
                            valueExpr: 'id_data_source_type',
                            displayExpr: 'descr',
                            value: idSourceType,
                            placeholder: 'Select connector',
                            onSelectionChanged: handleSetConnectorType
                        }}
                        validationRules={
                            [{ type: 'required' }]
                        }
                    />
                </GroupItem>
                <GroupItem key="connectorItems">
                {
                    FormBuilder({formData: props.formData, formOptions: connectorTypeData?.options, formItems: connectorTypeData?.items}) // FormBuilder is a component and it called as function to lelt render form children (see: https://supportcenter.devexpress.com/ticket/details/t956103/form-doesn-t-render-a-custom-component-that-renders-groupitem)
                }
                </GroupItem>
            </Form>
        </>
    );
};

export default ConnectorType;