/*
 * File: App.js
 * Project: kpc_rentastar_2024
 * File Created: Monday, 5th February 2024 9:29:24 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Tuesday, 18th June 2024 11:29:46 am
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */

// importing REACT-ROUTER
import { Route, Routes } from "react-router-dom";

// import dependencies
import { ChakraProvider } from "@chakra-ui/react";

// importing DEVEXTREME themes
// importing DEVEXTREME
import 'devextreme/dist/css/dx.light.css';
// import 'devextreme/dist/css/dx.material.blue.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
// import 'devextreme/dist/css/dx.fluent.blue.light.css';
// import 'devextreme/dist/css/dx.fluent.blue.light.compact.css';

// importing components
import HomePage from "./components/pages/site/HomePage";
import Login from "./components/pages/access/Login";

// importing contexts
import AuthContextProvider from "./contexts/site/AuthContext";
import UserContextProvider from "./contexts/site/UserContext";

// importing SASS
import "./App.scss";
import Translations from "./components/pages/admin/Translations";

function App() {
  sessionStorage.setItem('token', 'Qy0MYGbi0wfbT9pYO21Zoq9hYrMPGrDv')
  sessionStorage.setItem('expires', '2030-12-31 23:59:59')

  return (
    <ChakraProvider>
        <AuthContextProvider>
            <UserContextProvider>
                <Routes>
                    <Route index element={<HomePage />} />
                    <Route path="login" element={<Login />} />
                    <Route path="translations" element={<Translations />} />
                    <Route path="*" element={<>Pagina non disponibile</>} />
                </Routes>
            </UserContextProvider>
        </AuthContextProvider>
    </ChakraProvider>
  );
}

export default App;