import React from 'react';
import ClaxTable from '../../helpers/table/ClaxTable';
import { all } from 'axios';


function Translations() {

    const options = {
        url: process.env.REACT_APP_API_URL + '/admin/translations',
        keyField: "id_translation",
        allowDragReorder: false,
        defaultNumRows: 10,
        navigateToRow: null,
        editing: {
            mode: "popup",
            allowUpdating: true,
            allowDeleting: true,
            allowAdding : false,
            popup: {
                title: "Insert or update translation",
                showTitle: true,
                width: 400,
                height: 400
            }
        },
        exporting: {
            workbookTitle: "Translations",
            fileName: "translations",
            allowExportSelectedData: false,
            uploader: {

            }
        }
    }

    return (
        <ClaxTable { ...options } />
    )
}

export default Translations;