/*
 * File: GenericError.js
 * Project: kpc_rentastar_2024
 * File Created: Friday, 24th May 2024 11:34:04 am
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Friday, 24th May 2024 11:34:53 am
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */

export default function GenericError() {
    return(<p>This is an error</p>)
}