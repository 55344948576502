import React from 'react';

export const Format = (props) => {
    const { cell, row, format, children: value } = props;

    if(format === undefined) {
        return <div>{value}</div>;
    }

    let formattedValue = value;

    Object.keys(format).forEach((key) => {
        // const componentName = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
        const componentName = key
        const rules = format !== undefined ? JSON.parse(JSON.stringify(format[key])): undefined; // Create a copy of the rules object to avoid modifying the original object

        if(rules !== undefined && rules["condition"] !== undefined && rules["condition"][2] === undefined) {
            rules["condition"][2] = value;
        }

        try {
            const DynamicComponent = require(`./formatters/${componentName}.js`);
            formattedValue = <DynamicComponent.default rules={ rules }>{ formattedValue }</DynamicComponent.default>;
        } catch (error) {
            formattedValue = <div>{ formattedValue }</div>
        }
    });

    return formattedValue;
};

export const Link = (props) => {
    const { url, cell, value, children } = props;

    if(url === undefined) return children

    const queryParams = new URLSearchParams(window.location.search);
    if(queryParams.has(cell)) {
        return children
    }

    queryParams.set(url.fk, value);

    const newLocal = process.env.REACT_APP_PUBLIC_URL + '/reporting/viewer/' + url.id_report + '?' + queryParams.toString();

    return <a target="_blank" href= { newLocal }>{ children }</a>
}