/*
 * File: MainHeader.js
 * Project: kpc_rentastar_2024
 * File Created: Monday, 27th May 2024 3:20:55 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Tuesday, 18th June 2024 3:50:59 pm
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */
// importing CHAKRA
import { Box as ChakraBox, HStack as ChakraHStack, VStack as ChakraVStack } from "@chakra-ui/react"

// importing dependencies
import MainMenu from "../components/helpers/menu/MainMenu"
import Logo from "../assets/logo/LOGO_AUTO-CENTER-0x50.png"

const menu = [
    {title: "Home", to: "/", active: true},
    {title: "Login", to: "/login", active: true},
    {title: "Logout", to: "/logout", active: true},
]

export default function MainHeader() {
    return(
        <ChakraVStack
            h="100%"
            align='stretch'
            p={2}
        >
            <ChakraHStack
                w="100%"
                h="100%"
                justifyContent="space-between"
            >
                <ChakraBox>
                    <img src={Logo} alt="Application Logo" />
                </ChakraBox>
                <MainMenu menu={menu}/>
            </ChakraHStack>
        </ChakraVStack>
    )
}