export function evalRule(operand, op1, op2) {
    switch(operand) {
        case '==':
            return op1 === op2;
        case '!=':
            return op1 !== op2;
        case '>':
            return parseInt(op2) > parseInt(op1);
        case '<':
            return parseInt(op2) < parseInt(op1);
        case '>=':
            return parseInt(op2) >= parseInt(op1);
        case '<=':
            return parseInt(op2) <= parseInt(op1);
        case '&&':
            return op1 && op2;
        case '||':
            return op1 || op2;
        default:
            return false;
    }
}