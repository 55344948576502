/*
 * File: loaderFullHeight.js
 * Project: iwhplus
 * File Created: Thursday, 29th February 2024 3:41:52 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Thursday, 29th February 2024 3:52:45 pm
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */

// import DEVEXTREME
import Box, { Item } from 'devextreme-react/box';
import LoadIndicator from 'devextreme-react/load-indicator';

export default function LoaderFullHeight({size}) {

    return(
        <Box
          direction="row"
          width="100%"
          height="100%"
          align="center"
          crossAlign="center"
        >
          <Item ratio={0} baseSize={50}>
            <LoadIndicator id="small-indicator" height={size} width={size} />
          </Item>
        </Box>
    )
}