/*
 * File: ClaxTable.js
 * Project: kpc_rentastar_2024
 * File Created: Monday, 17th June 2024 4:11:47 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Friday, 12th July 2024 8:09:31 am
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */

/**
 * DESCRIPTION
 * text
 * 
 * TODO: implementare dare i dati come oggetto
 * 
 * PARAMS
 * @param: header e data
 * @param: dataOrigin = null | "local" => se local va a cercare un file
 * fetch => columns: "i nostri header", loading: false, error: null
 * fetch => data: "i nostri dati", loading: false, error: null
 * url, params, disableAdding, disaleColumnChooser, disableFilter, allowDragReorder, onRowSelected
 */

import React, { useEffect, useRef, useState } from 'react';

import DataGrid, { Column, ColumnChooser, Editing, Export, Form, Paging, SearchPanel, Toolbar } from 'devextreme-react/data-grid';
import { DateBox } from 'devextreme-react/date-box';
import { GroupItem, Item } from 'devextreme-react/form';
import { TextBox } from 'devextreme-react/text-box';
import { FilterBuilder } from 'devextreme-react/filter-builder';
import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import { Popover } from 'devextreme-react/popover';
import { Popup } from 'devextreme-react/popup';

import { exportToExcel } from '../export/ClaxExport';

import { Box, HStack } from '@chakra-ui/react';

import {consoleLog, t} from '../ClaxHelpers';
import { claxApi, claxNotify, useFetch } from '../ClaxApi';
import LoaderFullHeight from '../LoaderFullHeight';

import 'devextreme/dist/css/dx.light.css';
import {Format, Link} from './ClaxTableCell';

import './css/ClaxTable.scss';
import FormBuilder from '../form/ClaxFormBuilder';
import FileUploader from 'devextreme-react/cjs/file-uploader';
import { formatDate } from 'devextreme/localization';

const ClaxTable = (props) => {
    const [loadData, setLoadData] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null);
    const [navigateToRow, setNavigateToRow] = useState(props.navigateToRow??null);

    const { allowDragReorder, editing, exporting, disaleColumnChooser, filters, keyField, onRowSelected, params, preferences, url } = props;

    const [perPageRows, setPerPageRows] = useState(10);
    const [hilightedRowCount, setHilightedRowCount] = useState(0);
    const [filter, setFilter] = useState(null);
    const lastSelectedFilter = useRef(null);

    let formData = {}
    const [form, setForm] = useState(null)

    const dataGridRef = useRef(null);
    const fileUploaderRef = useRef(null);
    const [uploadedFiledId, setUploadedFiledId] = useState(null);
    const [isPopoverVisible, setPopoverVisible] = useState(false);

    const [uniqueId, ] = useState('table_' + Math.random().toString(36).substr(2, 9));

    const { data: userPreferences, loading: userPreferencesLoading, error: userPreferencesError} = useFetch({
        url: process.env.REACT_APP_API_URL + '/admin/user-preferences',
        method: 'GET',
        params: {
            filter: {
                context: preferences.context
            }
        }
    }, [])

    const { data: columns, loading: headerLoading, error: headerError} = useFetch({
        url: url + "/header",
        method: 'GET',
        params: {
            ...(params || {}),
        }
    }, [url, params])

    const { response: dataResponse, loading: dataLoading, error: dataError} = useFetch({
        url: url + "/data",
        method: 'GET',
        params: {
            ...(params || {}),
            ...(filter ? { filter } : {})
        }
    }, [url, params, loadData, filter])

    const data = dataResponse?.data;

    useEffect(() => {
        if(userPreferences === null) return

        if(userPreferences[0]?.preferences.perPageRows && userPreferences[0]?.preferences.perPageRows !== perPageRows) {
            setPerPageRows(userPreferences[0].preferences.perPageRows)
        }

    }, [userPreferences]);

    useEffect(() => {
        if(editing === undefined) return

        claxApi({
            url: url + "/form",
            method: 'GET',
            params: {
                ...(params || {}),
            }
        }).then((response) => {
            response.data.options = {...response.data.options, ["disableAsyncValidation"]: true}

            setForm(response.data)
        }).catch((error) => {
            claxNotify(error, "error")
        })
    }, [editing]);

    useEffect(() => {
        if(dataGridRef.current === null || !navigateToRow) return

        const dataGrid = dataGridRef.current.instance;

        dataGrid.navigateToRow(navigateToRow)

        consoleLog("Navigating to row " + navigateToRow)
    }, [navigateToRow]);

    useEffect(() => {
        if(dataGridRef.current === null) return

        if(selectedRow === null) {
            dataGridRef.current.instance.option("focusedRowIndex", -1);
            return
        }

        dataGridRef.current.instance.option("focusedRowIndex", dataGridRef.current.instance.getRowIndexByKey(selectedRow))

    }, [selectedRow]);

    if(userPreferencesError) {
        claxNotify(userPreferencesError, "error")
        return <></>
    }

    if(headerError) {
        claxNotify(headerError, "error")
        return <></>
    }

    if(dataError) {
        claxNotify(dataError, "error")
        return <></>
    }
    
    if(headerLoading || dataLoading || userPreferencesLoading) {
        return <LoaderFullHeight size="30"/>
    }

    if(!columns || columns.length === 0) {
        return <>No fields defined</>
    }

    /** Table management methods */
    const handleRowPerPage = (e) => {
        if(e === null || e.selectedItem === perPageRows) return

        setPerPageRows(e.selectedItem)

        claxApi({
            url: preferences.url,
            method: 'POST',
            data: {
                context: preferences.context,
                preferences: {
                    perPageRows: e.selectedItem,
                }
            }
        }).then((response) => {

        }).catch((error) => {
            claxNotify(error, "error")
        })
    }

    const forceLoadData = () => {
        if(dataLoading) return

        setLoadData(!loadData) // Toggle the loadData state to force the data reload
    }

    const handleAnomaliesData = (response) => {
        setPopoverVisible(true)
        setUploadedFiledId(response?.data?.id)
    }

    const handleAddButton = () => {
        const dataGrid = dataGridRef.current.instance;

        setSelectedRow(null);

        dataGrid.option("focusedRowIndex", -1)
        if(onRowSelected) onRowSelected(null);

        dataGrid.addRow();
    }

    const handleSave = (e) => {
        if(e.changes.length === 0) return

        const data = e.changes[0]

        let method = null;
        switch(data.type) {
            case 'insert':
                method = 'POST'
                break;
            case 'update':
                method = 'PUT'
                break;
            case 'remove':
                method = 'DELETE'
                break;
        }

        claxApi({
            url: url + (data.type === 'insert' ? '' : '/' + data.key),
            method: method,
            data: data.data
        }).then((response) => {
            setLoadData(!loadData)

            setNavigateToRow(response.data[keyField])
            setSelectedRow(response.data[keyField])

            claxNotify("Record " + (data.type === 'insert'?' inserted':' updated'), "success")
        }).catch((error) => {
            if(error.response?.data?.length > 0) {
                setLoadData(!loadData)
                claxNotify(error.response?.data[0].message, "error")
            } else {
                setLoadData(!loadData)
                claxNotify(error, "error")
            }
        })

        return
    }

    const handleOptionChanged = (e) => {
        if(preferences?.enabled !== true) return

        const dataGrid = dataGridRef.current.instance   

        switch (e.name) {
            case 'columns':
                claxApi({
                    url: preferences.url,
                    method: 'POST',
                    data: {
                        context: preferences.context,
                        preferences: {
                            visibleColumns: dataGrid.getVisibleColumns().filter(row => row.dataField !== undefined).map(row => row.dataField),
                        }
                    }
                }).then((response) => {

                }).catch((error) => {
                    claxNotify(error, "error")
                })
                return
        }
    }

    const handleExport = (e) => {
        const exportData = {
            [exporting.workbookTitle]: e.component
        };

        exportToExcel(exportData, exporting.fileName)
    }

    const handleUploadClick = () => {
        fileUploaderRef.current.instance._isCustomClickEvent = true
        fileUploaderRef.current.instance._$fileInput[0].click();
    };

    const onRowClick = (e) => {
        if (selectedRow === e.key) {
            setSelectedRow(null);
            dataGridRef.current.instance.option("focusedRowIndex", -1)

            if(onRowSelected) onRowSelected(null);
        } else {
            setSelectedRow(e.key);
            dataGridRef.current.instance.option("focusedRowIndex", dataGridRef.current.instance.getRowIndexByKey(e.key))

            if(onRowSelected) onRowSelected(e.key);
        }
    };
    /*** ***/

    /*** Table Options ***/

    const rowDragging = {
        allowReordering: true,
        allowDropInsideItem: true,
        onReorder: (e) => console.log(e)
    }

    /*** ***/

    const deepCopiedFormItems = JSON.parse(JSON.stringify(form?.items??[])); // Necessary to avoid the form items to be modified by the FormBuilder component

    const filteredRowCount = dataResponse?.headers['x-total-filtered-count'] ?? 0
    const unfilteredRowCount = dataResponse?.headers['x-total-unfiltered-count'] ?? 0

    console.log("Datagrid TC " + dataGridRef.current?.instance.totalCount() + ": Hilighted count: " + hilightedRowCount + " Total count: " + unfilteredRowCount + " Filtered count: " + filteredRowCount)

    return (
        // Sticky table header is defined in the App.scss file
        <>
            <DataGrid
                key={uniqueId}
                id={uniqueId}
                ref={dataGridRef}
                dataSource={data??[]}
                allowColumnReordering={true}
                allowColumnResizing={true}
                rowDragging={ allowDragReorder ? rowDragging : null}
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClick}
                keyExpr={keyField}
                focusedRowEnabled={true}
                // focusedRowKey={selectedRow}
                onSaving= {handleSave}
                onExporting={handleExport}
                // onFocusedRowChanged={(e) => {e.component.option("focusedRowIndex", e.rowIndex)}}
                onContentReady={(e) => {
                    setHilightedRowCount(e.component.totalCount()??0)

                    // After the table is rendered, set the focusedRowIndex to the selectedRow
                    if(selectedRow) {
                        const rowIndex = e.component.getRowIndexByKey(selectedRow)
                        e.component.option("focusedRowIndex", rowIndex)
                    } else {
                        e.component.option("focusedRowIndex", -1)
                    }
                }} // Cause two or three times rendering of the table and not only one. Also ClaxFilter is rendered twice or more
                onOptionChanged={handleOptionChanged}
            >
            {
                columns.map((column, index) => {
                    return <Column
                        key={index}
                        // dataField={column.dataField}
                        // caption={column.caption}
                        // visible={column.visible}
                        // allowSearch={column.allowSearch}
                        {...column}
                        cellRender={(cell) => cellRender(column.dataField, cell.value, column.displayFormat??undefined, cell.data, column.customParams??undefined)}
                    />
                })
            }
                <Toolbar>
                    <Item location={'before'}>
                        <SelectBox dataSource={[5, 10, 20, 50, 100]} onSelectionChanged={(e) => handleRowPerPage(e)} value={perPageRows}/>
                    </Item>
                    {filters?.enabled === true &&
                        <Item location={'before'}>
                            <ClaxFilter key={'filter_' + uniqueId} id={'filter_' + uniqueId} fields={columns} filter={filter} setFilter={setFilter} lastSelectedFilter={lastSelectedFilter} settings={filters} />
                        </Item>
                    }
                    <Item name="searchPanel" location="before"/>
                    <Item name="summary" location="center">Rendered rows: {hilightedRowCount} - Filtered rows: {filteredRowCount} - Unfiltered rows {unfilteredRowCount}</Item>
                    {(editing?.allowAdding === true) && 
                        <Item location='after'>
                            <Button name="addButton" icon="add" onClick={ handleAddButton }></Button>
                        </Item>
                    }
                    {
                        exporting !== undefined &&
                        <Item name="exportButton" locateInMenu="auto" location="after" />
                    }
                    {
                        exporting?.uploader !== undefined &&
                            <Item locateInMenu="auto" location="after">
                                <Button name="uploadButton" icon="upload" onClick={handleUploadClick}></Button>
                                <FileUploader
                                    ref={fileUploaderRef}
                                    style={{display:'none'}}
                                    name="uploaded_file[]"
                                    uploadUrl= {url + '/upload'}
                                    uploadHeaders={{
                                        'Access-Control-Allow-Origin': '*',
                                        'X-Requested-With': 'XMLHttpRequest',
                                        'X-Clax-Authorization': 'Bearer ' + sessionStorage.getItem('token')
                                    }}
                                    onUploaded={(e) => {
                                        const response = JSON.parse(e.request.response)
                                        claxNotify(response.message, response.notify)

                                        forceLoadData()
                                        handleAnomaliesData(response)
                                    }}
                                    onUploadError={(e) => {
                                        claxNotify(JSON.parse(e.error.response).message, "error")
                                    }}
                                ></FileUploader>
                            </Item>
                    }
                    <Item name="spacer" location="after"/>
                    <Item location='after'>
                        <Button name="refreshButton" icon="refresh" onClick={ forceLoadData }></Button>
                    </Item>
                    <Item name="spacer" location="after"/>
                    {(disaleColumnChooser === undefined || disaleColumnChooser === false) &&
                        <Item name="columnChooserButton" locateInMenu="auto" location="after" />
                    }
                </Toolbar>
                {
                    exporting !== undefined &&
                    <Export enabled={true} {...exporting}/>
                }
                <SearchPanel visible={true} placeholder="Quick hilight"/>
                <ColumnChooser enabled={true} mode="select"/>
                <Paging defaultPageSize={10} pageSize={perPageRows} onPageIndexChange={() => setSelectedRow(null)}/>
                {
                    editing !== undefined && form !== null &&
                    <Editing {...editing}>
                        <Form key={'form_' + uniqueId} {...form.options}>
                        {
                            FormBuilder({url: url, formData:{...formData}, formOptions: form.options, formItems: deepCopiedFormItems}) // FormBuilder is a component and it is called as function to let render form children (see: https://supportcenter.devexpress.com/ticket/details/t956103/form-doesn-t-render-a-custom-component-that-renders-groupitem)
                        }
                        </Form>
                    </Editing>
                }
            </DataGrid>
            {
                isPopoverVisible &&
                <Popover
                    visible={true}
                    position={'center'}
                    hideOnOutsideClick={true}
                    showCloseButton={true}
                    maxWidth={800}
                    width="auto"
                    shading={true}
                    shadingColor="rgba(0, 0, 0, 0.5)"
                    onHidden={() => setPopoverVisible(false)}
                >
                    <ClaxTableAnomalies
                        keyId={uploadedFiledId}
                        url={url + '/anomalies'}
                        exporting={{
                            workbookTitle: exporting.workbookTitle + " - Anomalies",
                            fileName: exporting.fileName + "_anomalies",
                            allowExportSelectedData: false,
                        }}
                    />
                </Popover>
            }
        </>
    );
};

const ClaxFilter = ({id, fields, filter, setFilter, lastSelectedFilter, settings}) => {
    const [filterValue, setFilterValue] = useState(filter)
    const [filterList, setFilterList] = useState()
    const [selectedFilter, setSelectedFilter] = useState(lastSelectedFilter.current)

    const [showFilterNamePopup, setShowFilterNamePopup] = useState(false)
    const [popupTitle, setPopupTitle] = useState('New filter name')
    const [buttonId, setButtonId] = useState('addFilterButton') // Track also action button
    const [newFilterName, setNewFilterName] = useState('')

    const [loadFilter, setLoadFilter] = useState(false);

    const filterBoxRef = useRef(null);

    const handleSaveFilter = () => {
        if(newFilterName === '') return

        const method = buttonId === 'addFilterButton' ? 'POST' : 'PUT'

        claxApi({
            url: settings.url + (method === 'POST' ? '' : '/' + selectedFilter),
            method: method,
            data: {
                context: settings.context,
                name: newFilterName,
                filter: filterValue
            }
        }).then((response) => {
            setSelectedFilter(response.data.id_filter)
            setShowFilterNamePopup(false)
            setLoadFilter(!loadFilter)
        }).catch((error) => {
            claxNotify(error, "error")
        })

        setNewFilterName('')
    }

    const hanldeAddFilter = (e) => {
        setNewFilterName('')
        setShowFilterNamePopup(true)
        setPopupTitle('New filter name')
        setButtonId('addFilterButton')
    }

    const handleUpdateFilter = (e) => {
        setShowFilterNamePopup(true)
        setPopupTitle('Update filter name')
        setButtonId('updateFilterButton')
    }

    const handleRemoveSavedFilter = () => {
        if(selectedFilter === 0) return

        claxApi({
            url: settings.url + '/' + selectedFilter,
            method: 'DELETE',
        }).then((response) => {
            setFilterValue(null)
            setSelectedFilter(null)
            setLoadFilter(!loadFilter)
        }).catch((error) => {
            claxNotify(error, "error")
        })
    }

    const handleApplyFilter = () => {
        setFilter(filterValue)
    }

    const handleClearFilter = () => {
        setFilterValue(null)
        setSelectedFilter(null)
        lastSelectedFilter.current = null
    }

    const handleSelectFilter = (e) => {
        if(e === null) {
            lastSelectedFilter.current = e
            setSelectedFilter(e)
            setNewFilterName('')
            setFilterValue(null)
            return
        }

        lastSelectedFilter.current = e
        setSelectedFilter(e)
        setNewFilterName(filterList.find(filter => filter.id_filter === e).name)
        setFilterValue(filterList.find(filter => filter.id_filter === e).filter)
    }

    useEffect(() => {
        claxApi({
            url: settings.url,
            method: 'GET',
            params: {
                filter: {
                    context: settings.context
                }
            }
        }).then((response) => {
            if(response.data.length) setFilterList(response.data)
        }).catch((error) => {
            claxNotify(error, "error")
        })
    }, [loadFilter])

    return (
        <>
            <HStack>
                <Button className={"button_" + id} name="filterButton" icon="filter" style={{backgroundColor: filter !== null?'darksalmon':''}}></Button>
            </HStack>
            <Popover
                target={".button_" + id}
                showEvent="click"
                position="right"
                width={500}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.5)"
                title='Filter builder'
            >
                <HStack>
                    <SelectBox
                        ref={filterBoxRef}
                        value={selectedFilter}
                        valueExpr="id_filter"
                        displayExpr="name"
                        items={filterList}
                        onValueChange={(e) => handleSelectFilter(e)}
                        // acceptCustomValue={true}
                        // onCustomItemCreating={handleSaveFilter}
                        noDataText='No filter saved'
                        placeholder='Saved filters'
                        showClearButton={true}
                    />
                    <Button id="addFilterButton" name="addFilterButton" icon="save" onClick={ hanldeAddFilter } />
                    <Button id="updateFilterButton" name="updateFilterButton" icon="pulldown" onClick={ handleUpdateFilter } disabled={selectedFilter?false:true} />
                    <Button name="removeFilterButton" icon="trash" onClick={ handleRemoveSavedFilter } />
                </HStack>
                <Box mt="5" minHeight={100}>
                    <FilterBuilder
                        value= {filterValue}
                        fields= {fields}
                        allowHierarchicalField={false}
                        onValueChanged={(e) => setFilterValue(e.value)}
                    />
                </Box>
                <HStack mt="5" justifyContent="space-between">
                    <Button text="Apply filter" onClick={() => handleApplyFilter() }></Button>
                    <Button text="Clear filter" onClick={() => handleClearFilter()} ></Button> 
                </HStack>
            </Popover>
            <Popup
                visible={showFilterNamePopup}
                title={popupTitle}
                width={300}
                height={200}
                position={{
                    of: '#' + buttonId,
                    my: 'top left',
                    at: 'bottom left',
                    offset: '0 5', // Opzionale: aggiusta la posizione con un offset x y
                }}
            >
                <TextBox label="Filter name" placeholder='Insert filter name' showClearButton={true} onValueChange={(v) => {setNewFilterName(v)}} value={newFilterName}></TextBox>
                <HStack mt="5" justifyContent="space-between">
                    <Button text="Save filter" onClick={handleSaveFilter} />
                    <Button text="Cancel" onClick={() => {
                        setShowFilterNamePopup(false)
                    }} />
                </HStack>
            </Popup>
        </>
    )
}

const ClaxTableAnomalies = ({url, exporting, keyId}) => {
    const dataGridRef = useRef(null);

    useEffect(() => {

    }, [keyId]);

    const { data, loading, error} = useFetch({
        url: url,
        method: 'GET',
        params: {
            id: keyId
        }
    }, [url, keyId])

    const handleExport = (e) => {
        const exportData = {
            [exporting.workbookTitle]: e.component
        };

        exportToExcel(exportData, exporting.fileName)
    }

    return (
        <>
            <DataGrid
                key="anomaliesTable"
                ref={dataGridRef}
                allowSearch={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                rowAlternationEnabled={true}
                showBorders={true}
                keyExpr="id_anomaly"
                onExporting={handleExport}
                dataSource={data}
                exporting={exporting}
            >
                <Toolbar>
                    <Item location="before">
                        Uploading anomalies
                    </Item>
                    <Item name="searchPanel" location="before"/>
                    {
                        exporting !== undefined &&
                        <Item name="exportButton" locateInMenu="auto" location="after" />
                    }
                </Toolbar>
                <Column dataField="id_anomaly" caption="ID" allowSearch={true} width={50}/>
                <Column dataField="row_number" caption="Row number" allowSearch={true} width={100}/>
                <Column dataField="action" caption="Action" allowSearch={true} width={100}/>
                <Column dataField="field" caption="Field" allowSearch={true} width={100}/>
                <Column dataField="message" caption="Message" allowSearch={true} width={600}/>
                <Paging defaultPageSize={20}/>
                <SearchPanel visible={true} placeholder="Quick hilight"/>
                {
                    exporting !== undefined &&
                    <Export enabled={true} {...exporting}/>
                }
            </DataGrid>
        </>
    );
};

const cellRender = (cell, value, displayFormat, row, customParams) => {
    if(value instanceof Date) {
        value = formatDate(value, displayFormat??'yyyy-MM-dd HH:ii:ss')
    }

    return (
        <Link url= {customParams?.link??undefined} cell={ cell } value= {value}>
            <Format cell={cell} row={row} format={ customParams?.format??undefined }>{value}</Format>
        </Link>
    )
}

export default ClaxTable;
