/*
 * File: Login.js
 * Project: kpc_rentastar_2024
 * File Created: Thursday, 21st March 2024 3:07:50 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Monday, 27th May 2024 4:50:43 pm
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */

import React, { useState } from "react";

// import LAYOUT
import SimpleCenteredLayout from "../../../layouts/SimpleCenteredLayout";

// import helpers
import { claxApi } from "../../helpers/ClaxApi";

// import CHAKRA
import { Box, VStack, useToast, FormControl, Button, Input, InputRightElement, InputGroup } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

export default function Login(props) {
  const [networkError, setNetworkError] = useState(null)

  // setup context
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState()
  const toast = useToast()

  // login button state managing
  const [isLoading, setLoading] = useState();

  const handleClick = () => {

    // checking if the user has set the required data
    if (!username || !password) {

      switch (true){
        case !username:
          toast({
            title: 'Errore',
            description: 'Utente non trovato',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
          break
        case !password:
          toast({
            title: 'Errore',
            description: 'Password mancante',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
          break
        default:
          toast({
            title: 'Errore',
            description: 'Caso strano (utente o password mancante)',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
      }
    } else {

      // changing state for the loading button and animation
      setLoading(true);
    }

    claxApi({
      url: process.env.REACT_APP_API_URL + '/access/login',
      method: 'POST',
      data: {
        username: username,
        password: password
      }
    })
    .then(response => {

      props.setAuthProvider({
        isLogged: true,
        token: response.data.token,
        expires: response.data.expires,
      });

      /** E' necessario per caricare lo UserProvider in fase di login */
      props.setUserProvider({
        firstName: response.data.firstName, 
        lastName: response.data.lastName, 
        isAdmin: response.data.isAdmin,
        isGroupAdmin: response.data.isGroupAdmin
      })

      props.setMenu(response.data.menu)

      // setting localstorage to persist data
      sessionStorage.setItem('token', response.data.token);
      sessionStorage.setItem('expires', response.data.expires);

    })
    .catch(error => {
      setNetworkError(error);
      toast({
        title: 'Errore',
        description: 'Autenticazione fallita, controllare i dati',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    });
  }

  // catch the ENTER key to submit
  const handleKeyDown = (e) => {
    // alternative da provare
    // (e.keyCode === 13)
    // (e.key === 'Enter')
    // (e.nativeEvent.key === 'Enter')
    // (e.keyCode === 13) && console.log(`You pressed the ENTER key`)
    (e.key === 'Enter') && handleClick()
  };

  // show/hide Passwords
  const handleClickShowPassword = () => setShowPassword(!showPassword)

  if(networkError !== null) {
    setNetworkError(null)
    setLoading(false)
  }

  return (
          <SimpleCenteredLayout >
            <form>
              <VStack
                width="20vw"
                id="login_container"
                boxShadow='dark-lg'
                borderRadius={8}
                bg="white"
                p={8}
              >
                  <FormControl isRequired>
                  <Input
                    name="username"
                    autoComplete="username"
                    type="text"
                    placeholder="Nome utente"
                    id="Username"
                    className="bg-light form-control"
                    required
                    onChange={e => {
                      const username = e.target.value

                      if (username){ setUsername(username) }
                    }}
                    onKeyDown={handleKeyDown}
                  />
                  </FormControl>

                  <FormControl isRequired>
                    <InputGroup size='md'>
                      <Input
                        name="password"
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        id="password"
                        className="bg-light form-control"
                        required
                        onChange={e => {
                          const password = e.target.value
                          if (username){ setPassword(password) }
                        }}
                        onKeyDown={handleKeyDown}
                      />
                      <InputRightElement width='4.5rem'>
                        <Button h='1.75rem' size='sm' onClick={handleClickShowPassword}>
                          {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <Box align="center">
                    <Button
                      mt="4"
                      isLoading = {(isLoading) ? true : false}
                      loadingText='Accesso in corso'
                      colorScheme='blue'
                      onClick={handleClick}
                      mb={8}
                    >
                      Clicca per accedere
                    </Button>
                  </Box>
              </VStack>
            </form>
        </SimpleCenteredLayout>
  );
}
