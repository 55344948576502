import React from 'react';
import { Box } from '@chakra-ui/react';
import Form, { GroupItem, ButtonItem, SimpleItem, RequiredRule } from 'devextreme-react/form';
import { claxApi, claxNotify, useFetch } from '../ClaxApi';
import LoaderFullHeight from '../LoaderFullHeight';
import FormBuilder from './ClaxFormBuilder';
import { consoleLog } from '../ClaxHelpers';

const ClaxForm = (props) => {
    const { url, method, title } = props;

    const buttonLabel = method.toUpperCase() === "POST" ? "Add new" : "Update"

    /*** Update field value management */
    const { data: formSettings, loading: formSettingsLoading, error: formSettingsError} = useFetch({
        url: url + "/form",
    }, [url + "/form"])

    const { data: formData, loading: formDataLoading, error: formDataError} = useFetch({
        url: url
    }, [url], {})

    /*** */

    consoleLog(["loading setting",formSettingsLoading, "loding data", formDataLoading])
    if(formSettingsLoading || formDataLoading) {
        return <LoaderFullHeight size="30"/>
    }
    
    if(formSettingsError) {
        claxNotify(formSettingsError, "error")
        return <></>
    }

    if(formDataError && formDataError.response?.status !== 404) {
        claxNotify(formDataError, "error")
        return <></>
    }

    const formOptions = formSettings?.options??{} // Options is an object
    const formItems = formSettings?.items??[] // Items is an array

    const handleUpdateForm = (e) => {
        e.preventDefault()

        claxApi({
            method: method,
            url: url.replace(/\/0$/, ''),
            data: formData
        }).then((response) => {
            claxNotify("Data updated", "success")
        }).catch((error) => {
            claxNotify(error, "error")
        })
    }

    return (
        <>
            <form
                action="#"
                onSubmit={handleUpdateForm}
            >
                <Form formData={formData} {...formOptions}>
                    <GroupItem key="formItems" caption={title}>
                        {
                            FormBuilder({url: url, formData: formData, formOptions: formOptions, formItems: formItems.slice()}) // FormBuilder is a component and it called as function to lelt render form children (see: https://supportcenter.devexpress.com/ticket/details/t956103/form-doesn-t-render-a-custom-component-that-renders-groupitem)
                        }
                    </GroupItem>
                    <GroupItem key="formButtons">
                        <ButtonItem key="resetButton" buttonOptions={{text:"Reset", width:"120px", onClick: (e) => {props.formData = {}} }}/>
                        <ButtonItem key="submitButton" buttonOptions={{text: buttonLabel, width:"120px" , useSubmitBehavior: true}}/>
                    </GroupItem>
                </Form>
            </form>
        </>
    );
}

export default ClaxForm;