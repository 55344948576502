/*
 * File: MainGridLayout.js
 * Project: kpc_rentastar_2024
 * File Created: Sunday, 26th May 2024 4:26:03 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Monday, 27th May 2024 3:39:14 pm
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */

// importing CHAKRA
import { Grid } from '@chakra-ui/react'

export default function MainGridLayout({children}) {
    return(
        <Grid
            id="main_grid"
            className="App"
            templateAreas={`"header"
                            "main"
                            "footer"`}
            gridTemplateRows={'150px 1fr 50px'}
            gridTemplateColumns={'1fr'}
            h="100vh"
        >
        {children}
      </Grid>
    )
}