/*
 * File: MainMenu.js
 * Project: kpc_rentastar_2024
 * File Created: Monday, 27th May 2024 3:02:55 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Tuesday, 28th May 2024 4:34:15 pm
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */

// importing CHAKRA
import { Box, HStack } from "@chakra-ui/react"

// importing dependencies
import { Link } from "react-router-dom";

export default function MainMenu({menu}) {

    return(
        <HStack
            id="main_nav"
        >
            {
                menu.filter( e => e.active === true ).map( (e, index) => {
                    return <Link key={index} to={e.to}>{e.title}</Link>
                })
            }
        </HStack>
    )
}