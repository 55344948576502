/*
 * File: fullSizeLayout.js
 * Project: kpc_rentastar_2024
 * File Created: Sunday, 26th May 2024 4:25:51 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Sunday, 26th May 2024 6:01:49 pm
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */

// importing CHAKRA
import { Grid, GridItem } from "@chakra-ui/react"

export default function SimpleCenteredLayout({children}) {
    return(
        <Grid
            id="outer_container"
            height="100vh"
            alignItems="center"
            justifyContent="center"
        >
            <GridItem>
                {children}
            </GridItem>
        </Grid>
    )
}