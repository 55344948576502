import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

export const exportToExcel = (data, filename) => {
    const workbook = new Workbook();

    Object.keys(data).forEach(key => {
        const worksheet = workbook.addWorksheet(key);
        exportDataGrid({
            component: data[key],
            worksheet: worksheet,
            customizeCell: function(options) {
                options.excelCell.font = { name: 'Arial', size: 12 };
                options.excelCell.alignment = { horizontal: 'left' };
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename + '_' + (new Date().toISOString().slice(0, 10)) + '.xlsx');
                });
        }) 
    });
};