/*
 * File: CarModel.js
 * Project: kpc_rentastar_2024
 * File Created: Thursday, 11th July 2024 5:56:12 pm
 * Author: Manuel Sosi (manuel.sosi@clearaxess.com)
 * -----
 * Last Modified: Friday, 12th July 2024 8:03:01 am
 * Modified By: Manuel Sosi (manuel.sosi@clearaxess.com>)
 * -----
 * Copyright 2024 CLEARAXESS SAGL Via alla Campagna, 4 6900 Lugano (CH) CHE, CLEARAXESS SAGL
 */

// importing REACT
import React from 'react';

// importing CHAKRA
import { Box as ChakraBox, VStack as ChakraVStack, Text as ChakraText } from '@chakra-ui/react';

// importing dependencies
import ClaxTable from '../../helpers/table/ClaxTable';
import LoaderFullHeight from '../../helpers/LoaderFullHeight';
import { claxNotify, useFetch } from '../../helpers/ClaxApi';
import { parseDate } from 'devextreme/localization';

function CarModel() {

    const options = {
        url: process.env.REACT_APP_API_URL + '/drm/car-model',
        keyField: "id_model",
        allowDragReorder: false,
        defaultNumRows: 10,
        navigateToRow: null,
        editing: {
            mode: "popup",
            allowUpdating: true,
            allowDeleting: true,
            allowAdding : true,
            popup: {
                title: "Insert or update car model",
                showTitle: true,
                width: 400,
                height: 400
            }
        },
        exporting: {
            workbookTitle: "Car models",
            fileName: "car-models",
            allowExportSelectedData: false,
            uploader: {

            }
        },
        preferences: {
            enabled: true,
            url: process.env.REACT_APP_API_URL + '/admin/user-preferences',
            context: 'car-model',
            perPageRows: 10
        },
        filters: {
            enabled: true,
            url: process.env.REACT_APP_API_URL + '/admin/user-filter',
            context: 'car-model'
        }
    }

    return (
        <ChakraVStack align='stretch'>

            <ChakraBox
                p="1rem"
                id="page_content"
            >
                <header>
                    <ChakraText fontSize='2xl'>Car Models</ChakraText>
                    <p></p>
                </header>
            </ChakraBox>
            <ChakraBox>
                <ClaxTable { ...options } />
            </ChakraBox>
        </ChakraVStack>
    )
}

export default CarModel;