import { useEffect, useRef, useState } from "react"
import { consoleLog, isEmpty } from "./ClaxHelpers";
import axios, { AxiosError } from 'axios';
import notify from "devextreme/ui/notify";

// export function validateToken() {
//     const token = sessionStorage.getItem('token');
//     const expires = sessionStorage.getItem('expires');

//     if(token === "null") return false;
//     if(expires === "null") return false;
    
//     const expirationDate = new Date(expires).getTime();

//     if(Date.now() >= expirationDate) return false;

//     return true;
// }

export const refreshToken =  async () => {
    return await claxApi({
        url: process.env.REACT_APP_API_URL + '/access/refresh-token',
        method: 'POST',
    }).then((response) => {
        sessionStorage.setItem('token', '7636190EFE4D79F35076B5B3661752B5')
        sessionStorage.setItem('expires', '2030-12-31 23:59:59')

        return response
    })
}

export const claxApi = async (options) => {
    const token = sessionStorage.getItem('token');

    options.headers = {
        'Access-Control-Allow-Origin': '*', // CORS
        'X-Requested-With': 'XMLHttpRequest' // XHR
    };

    if(token) {
        options.headers['X-Clax-Authorization'] = 'Bearer ' + token;
    }

    return await axios(options).catch((error) => {
        if(error.response && error.response.status === 401) {
            window.location.href = process.env.REACT_APP_OAUTH2_AUTHORIZE_URL
                + "?client_id=" + process.env.REACT_APP_OAUTH2_CLIENT_ID
                + "&response_type=code"
                + "&scope=openid"
                + "&callback=" + process.env.REACT_APP_OAUTH2_CALLBACK_URL
                + "&state=" + generateState();
        }

        throw error
    });
}

export const useFetch = (options, dependencies, defaultValue) => {
    const [response, setReponse] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;

        setLoading(true);

        const fetchData = async () => {
            consoleLog(["UseFetch: Fetching data from " + options.url, "Loading", loading]);

            try {
                const apiResponse = await claxApi(options)

                setReponse(apiResponse)
                setError(null)
            } catch (apiError) {
                setError(apiError)
                setReponse(null)
            } finally {
                setLoading(false)
            }
        };

        if(options !== undefined) {
            fetchData()
        }

    }, dependencies);

    let data = null;

    if(error) {
        data = defaultValue??null
    } else {
        data = response === null?null:(isEmpty(response.data)?defaultValue??null:response.data);
    }

    return {response, data, loading, error };
};

export const claxNotify = (object, type) => {
    consoleLog(["Notify: ", object, type])
    let message;
    if(object instanceof AxiosError) {
        message = object.response && object.response.data?.message?object.response.data?.message:object.message;
    } else {
        message = object;
    }
    
    notify(message, type);
}

/*** OAuth2 helpers */

function generateState() {
    if (window.crypto && window.crypto.getRandomValues) {
        // Use browser's crypto API (supported in modern browsers)
        const randomBytes = new Uint8Array(32);
        window.crypto.getRandomValues(randomBytes);
        return btoa(String.fromCharCode(...randomBytes)); // Encode to Base64
    } else {
        // Fallback for older browsers (not cryptographically secure)
        const randomString = Math.random().toString(36).substr(2, 32); // Generate random alphanumeric string
        return randomString;
    }
}